<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div>分会干事</div>
    </div>
    <div class="thetitle">IADGE分会干事</div>
    <div class="profile">
      <div class="title"><div class="infor">编辑分会干事</div></div>
      <div class="row">
        <div class="titler">姓名：</div>
        <div class="input phone">{{ lastname.memberdetails.member_name }}</div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">邮箱：</div>
          <div class="input">{{ lastname.memberdetails.member_email }}</div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">职位：</div>
          <div class="input">{{ lastname.memberdetails.member_position }}</div>
        </div>
      </div>

      <div class="row">
        <div class="nation">
          <div class="titler">会员编号：</div>
          <div class="input">{{ lastname.memberdetails.member_number }}</div>
        </div>
      </div>

      <div class="row date">
        <div class="nation">
          <div class="titler">任职开始日期：</div>
          <div class="input">
            <div class="hintsize">
              本届任期开始时间：{{
                lastname.memberdetails.start_time?.substring(0, 4)
              }}年{{ lastname.memberdetails.start_time?.substring(5, 7) }}月{{
                lastname.memberdetails.start_time?.substring(8, 10)
              }}日
            </div>
            <a-date-picker
              size="large"
              v-model="lastname.start_time"
              placeholder="请选择日期"
              @change="getdate"
            />
          </div>
        </div>
        <div class="city">
          <div class="titler">任职结束日期：</div>
          <div class="input">
            <div class="hintsize">
              本届任期结束时间：
              {{ lastname.memberdetails.end_time?.substring(0, 4) }}年{{
                lastname.memberdetails.end_time?.substring(5, 7)
              }}月{{ lastname.memberdetails.end_time?.substring(8, 10) }}日
            </div>
            <a-date-picker
              size="large"
              v-model="lastname.end_time"
              placeholder="请选择日期"
              @change="getdate1"
            />
          </div>
        </div>
      </div>

      <div
        class="save hoverbottom"
        @click="membersubmit()"
        :class="{ click: submit }"
      >
        保存
      </div>
    </div>
  </div>
</template>
        
    <style lang="scss">
.ant-picker-large {
  width: 100%;
  height: 64px;
  border: 2px solid #777777 !important;
  padding: 20px 20px !important;
  box-sizing: border-box;
  .anticon svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
</style>
    <script>
import { memberdetail, memberedit } from "../api/twice";
import { ElNotification } from "element-plus";
export default {
  name: "Home",
  data() {
    return {
      lastname: {
        name: "",
        email: "",
        start_time: "",
        end_time: "",
        position: "",
        street: "",
        Other_address: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        date: "",
        memberdetails: [],
      },
    };
  },
  methods: {
    getdate(v, model) {
      this.lastname.start_time = model;
    },
    getdate1(v, model) {
      this.lastname.end_time = model;
    },
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    async getmember(e) {
      const data = await memberdetail({
        id: e,
      });
      this.lastname.memberdetails = data;
      console.log(this.memberdetails, data, 8848);
    },
    async membersubmit() {
      console.log(this.lastname.end_time, this.lastname.start_time);
      if (!this.submit) return;
      const data = await memberedit(this.$route.query.id, {
        start_time: this.lastname.start_time,
        end_time: this.lastname.end_time,
      });
      if(data){
        ElNotification({
          title: "Success",
          message: "分会干事信息修改成功!",
          type: "success",
        });
        setTimeout(() => {
          this.$router.push("/Incumbentofficer");
        }, 300);
      }
      // console.log(data, 9876543);
    },
  },
  computed: {
    submit() {
      if (this.lastname.start_time && this.lastname.end_time) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getmember(this.$route.query.id);
    // console.log(this.$route.query.id, 999);
  },
  components: {},
};
</script>
        <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;

    // border-image: linear-gradient(45deg, #f06, #00f) 1;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    border: 2px solid #333333;
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #ccc;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;
      cursor: no-drop;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        // .hint {
        //   font-size: 12px;
        //   font-weight: 400;
        //   color: #333333;
        //   line-height: 14px;
        //   margin-top: 4px;
        //   position: absolute;
        //   margin-bottom: 20px;
        //   margin-left: 20px;
        .hintsize {
          position: absolute;
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          top: -24px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .date {
      margin-top: 46px;
    }

    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
        